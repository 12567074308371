<template>
    <div class="modal-fix">
        <div class="account-content">
            <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
                <div class="section-title margin-deliver">{{ $t("Billing")}}</div>
            </div>
            <div class="row" v-if="isContentLoading">
                <div class="col" v-for="index in 2" :key="index">
                    <BillingLoader></BillingLoader>
                </div>
            </div>
            <div class="row row-cols-md-2" v-else>
                <div class="col">
                    <div class="billing-card details__card gutter-10">
                        <div class="details__card__head">
                            <h4 class="font-weight-bold">{{ $t("Billing Details")}}</h4>
                            <a href="#" class="edit-icon" @click.prevent="showBillingModal"><i class="eicon e-pencil"></i></a>
                        </div>
                        <div class="details__card__body">
                            <p class="color-dark">{{billing.name}}</p>
                            <p class="color-secondary"><a :href="`mailto:${billing.email}`">{{billing.email}}</a></p>
                            <p class="color-secondary" v-if="billing.address_line_1">{{billing.address_line_1}}</p>
                            <p class="color-secondary" v-if="billing.address_line_2">{{billing.address_line_2}}</p>
                            <p class="color-secondary" v-if="billing.location">{{billing.location}}</p>
                            <p class="color-secondary" v-if="billing.country">{{billing.country.name}}</p>
                            <p class="color-dark" v-if="billing.postal_code">{{billing.postal_code}}</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="billing-card details__card gutter-10">
                        <div class="details__card__head">
                            <h4 class="font-weight-bold">{{ $t("Payment Information")}}</h4>
                            <a href="#" class="edit-icon" @click.prevent="showPaymentInfoModal"><i class="eicon e-pencil"></i></a>
                        </div>
                        <h4 class="empty-message text-center" v-if="isEmpty">{{ $t("no card information found")}}</h4>
                        <div class="details__card__body" v-else>
                            <p class="color-dark">{{ $t("Card Holder Name")}}: <span class="color-secondary">{{paymentInfo.card_holder_name}}</span></p>
                            <p class="color-dark">{{ $t("Card Name")}}: <span class="color-secondary">{{paymentInfo.card_brand}}</span></p>
                            <p class="color-dark">{{ $t("Card Number")}}: <span class="color-secondary">**** **** **** {{paymentInfo.card_last_four}}</span></p>
                        </div>

                    </div>
                </div>
            </div>
        </div>


        <modal-billing-form
                :billing="billing"
                v-if="billingModalShow"
                @closeModal="hideBillingModal"
        ></modal-billing-form>

        <modal-payment-info-form
                v-if="paymentInfoModalShow"
                @closeModal="hidePaymentInfoModal"
        ></modal-payment-info-form>
    </div>
</template>

<script>
    import client from "../../app/api/Client";
    const ModalBillingForm = () => import("../../components/modal/user/BillingForm.vue");
    const ModalPaymentInfoForm = () => import("../../components/modal/user/PaymentInfoForm.vue");
    const BillingLoader = () =>  import("../../components/_loaders/BillingLoader.vue");

    export default {
        components: {
            ModalBillingForm,
            ModalPaymentInfoForm,
            BillingLoader
        },
        data() {
            return {
                isContentLoading: false,
                billing: [],
                paymentInfo: {},
                billingModalShow: false,
                paymentInfoModalShow: false,
                isLoading: false,
            }
        },

        computed: {
            isEmpty() {
                if(this.paymentInfo.card_brand === null && this.paymentInfo.card_holder_name === null && this.paymentInfo.card_last_four === null) {
                    return true;
                }
            }
        },

        methods: {
            async getBillingDetails() {
                this.isContentLoading = true;
                try {
                    let {data} = await client().get(`/my-account/billing`);
                    if (data.status === 'SUCCESS') {
                        this.billing = data.data.billing;
                        this.paymentInfo = data.data.payment_info;
                    } else {
                        this.$toast.error(this.$t(data.message));
                    }
                } catch (e) {
                }
                this.isContentLoading = false;
            },
            showBillingModal() {
                this.billingModalShow = true;
                const el = document.body;
                el.classList.add('modal-open');
            },
            hideBillingModal() {
                this.billingModalShow = false;
                const el = document.body;
                el.classList.remove('modal-open');
                this.getBillingDetails();
            },
            showPaymentInfoModal() {
                this.paymentInfoModalShow = true;
                const el = document.body;
                el.classList.add('modal-open');
            },
            hidePaymentInfoModal() {
                this.paymentInfoModalShow = false;
                const el = document.body;
                el.classList.remove('modal-open');
                this.getBillingDetails();
            },
        },

        async mounted() {
            await this.getBillingDetails();
        }
    }
</script>

<style>
    .modal-fix {
        display: flex;
        flex: 1;
    }
    .notices .toast .toast-text {
        word-break: initial!important;
    }
</style>
